<template>
  <el-container class="base-container">
    <el-header class="base-height">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>组织权限</el-breadcrumb-item>
        <el-breadcrumb-item>角色管理</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-container class="main-container" ref="mainSector">
      <el-aside width="20%" style="padding: 20px 5px 0 20px;">
        <div style="display: flex;">
          <span>组织机构</span>
          <span style="margin-left: auto; margin-right: 10px">
            <el-tooltip effect="dark" content="展开/收起" placement="top" >
              <el-link :underline="false" @click="toggleRowExpansion()">
                <el-icon :size="20"><Sort /></el-icon>
              </el-link>
            </el-tooltip>
          </span>
        </div>
        <el-divider style="margin: 15px 0;"/>
        <div style="height: calc(100% - 65px); overflow: auto;">
          <el-tree
              :data="treeData"
              :props="treeProps"
              node-key="value"
              :default-expand-all="expanded"
              :expand-on-click-node="false"
              ref="organTree">
            <template #default="{ node, data }">
                <span @click="toShowOrgan(data)">
                  <i class="iconfont icon-zuzhiguanli" style="font-size: 18px;" v-if="data.type === 'company'"></i>
                  <i class="iconfont icon-tree-department-1" style="font-size: 18px;" v-else></i>
                  {{ node.label }}
                </span>
            </template >
          </el-tree>
        </div>
      </el-aside>
      <el-main style="height: 100%; border-left: 10px solid #f5f5f5; overflow:hidden;">
        <div style="display: flex;">
          <el-button type="success" @click="toAdd()">
            <el-icon><Plus /></el-icon><span class="icon-right">新建角色</span>
          </el-button>
          <div style="margin-left: auto;">
            <el-tooltip effect="dark" content="刷新" placement="top">
              <el-link :underline="false" @click="toRefresh()">
                <el-icon :size="20"><Refresh /></el-icon>
              </el-link>
            </el-tooltip>
          </div>
        </div>
        <el-divider style="margin: 6px 0;"/>
        <div style="height: calc(100% - 5px); overflow: auto">
          <el-table
              :height="tableHeight"
              :data="roleData"
              style="width: 100%"
              v-loading="loading">
            <template v-slot:empty>
              <el-empty description="暂无数据" :image-size="120"></el-empty>
            </template>
            <el-table-column label="序号" type="index" width="60px" align="center"/>
            <el-table-column
                prop="ROLE_NAME"
                label="角色名称"
                show-overflow-tooltip
                align="center"/>
            <el-table-column
                prop="ORGAN_DEP"
                label="所属组织"
                show-overflow-tooltip
                align="center"/>
            <el-table-column
                prop="CREATE_TIME"
                label="创建时间"
                min-width="80px"
                show-overflow-tooltip
                align="center"/>
            <el-table-column
                label="状态"
                fixed="right"
                width="80px"
                align="center">
              <template #default="scope">
                <el-switch
                    v-model="scope.row.STATE"
                    active-value="1"
                    inactive-value="0"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    inline-prompt
                    active-text="启"
                    inactive-text="禁"
                    @change="switchState(scope.row)">
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                align="center"
                width="180px">
              <template #default="scope">
                <el-button class="table-button"
                           size="small"
                           type="primary"
                           @click="toEdit(scope.row)">编辑</el-button>
                <el-button class="table-button"
                           size="small"
                           type="danger"
                           @click="toDel(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
              background
              v-model:current-page="search.page"
              v-model:page-size="search.pagesize"
              layout="total, prev, pager, next, jumper"
              @current-change="loadRole"
              :total="total"
              style="margin-top: 10px;">
          </el-pagination>
        </div>
      </el-main>
      <el-dialog
          width="700px"
          :title="roleTitle"
          v-model="dailVisi"
          :close-on-click-modal="false"
          :close-on-press-escape="false">
        <div style="height: calc(100% - 100px); overflow: auto;">
          <el-form
              :inline="true"
              :model="roleForm"
              :rules="rules"
              ref="roleForm"
              label-width="130px"
          >
            <el-form-item label="所属组织" prop="role_dep" class="form-row">
              <el-cascader
                  style="width: 100%;"
                  v-model="roleForm.organ_dep"
                  :props="props"
                  clearable
                  @change="toRoleDep($event)"
                  :options="treeData" >
              </el-cascader>
            </el-form-item>
            <el-form-item label="角色名称:" prop="username" class="form-row">
              <el-input v-model="roleForm.rolename" />
            </el-form-item>
            <el-form-item label="查询范围:" prop="username" class="form-row">
              <el-radio-group v-model="roleForm.queryRange">
                <el-radio label="1" border>公司数据</el-radio>
                <el-radio label="2" border>部门数据</el-radio>
                <el-radio label="3" border>个人数据</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="状态:" prop="state" class="form-col">
              <el-switch
                  v-model="roleForm.state"
                  active-value="1"
                  inactive-value="0"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  inline-prompt
                  active-text="启用"
                  inactive-text="停用">
              </el-switch>
            </el-form-item>
            <el-form-item label="是否凭证审核:" prop="is_voucher" class="form-col">
              <el-switch
                  v-model="roleForm.is_voucher"
                  active-value="1"
                  inactive-value="0"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  inline-prompt
                  @change="toVoucher($event)"
                  active-text="是"
                  inactive-text="否">
              </el-switch>
            </el-form-item>
<!--            <el-form-item label="是否报销审核:" prop="is_expense" class="form-col">-->
<!--              <el-switch-->
<!--                  v-model="roleForm.is_expense"-->
<!--                  active-value="1"-->
<!--                  inactive-value="0"-->
<!--                  active-color="#13ce66"-->
<!--                  inactive-color="#ff4949"-->
<!--                  inline-prompt-->
<!--                  @change="toExpense($event)"-->
<!--                  active-text="是"-->
<!--                  inactive-text="否">-->
<!--              </el-switch>-->
<!--            </el-form-item>-->

<!--            <el-form-item label="审核顺序:" prop="expense_num" class="form-col">-->
<!--              <el-input v-model="roleForm.expense_num" :disabled="expense"/>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="是否请假审核:" prop="is_ask_leave" class="form-col">-->
<!--              <el-switch-->
<!--                  v-model="roleForm.is_ask_leave"-->
<!--                  active-value="1"-->
<!--                  inactive-value="0"-->
<!--                  active-color="#13ce66"-->
<!--                  inactive-color="#ff4949"-->
<!--                  inline-prompt-->
<!--                  @change="toAskLeave($event)"-->
<!--                  active-text="是"-->
<!--                  inactive-text="否">-->
<!--              </el-switch>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="审核顺序:" prop="ask_leave_num" class="form-col">-->
<!--              <el-input v-model="roleForm.ask_leave_num" :disabled="ask_leave"/>-->
<!--            </el-form-item>-->
          </el-form>
          <div class="div-button">
            <el-button type="primary" @click="toSaveRole">保 存</el-button>
          </div>
        </div>
      </el-dialog>
    </el-container>
  </el-container>
</template>

<script>
export default {
  name: "RoleMge",
  data () {
    return {
      expanded:true,
      treeData: [],
      treeProps:{
        label:'text', value:'value', children:'children',
      },
      tableHeight: 0,
      roleData:[],
      total: 0,
      search: {
        organid:"",
        depid:"",
        page: 1,
        pagesize: 10,
      },
      loading:false,
      roleTitle: "",
      dailVisi: false,
      voucher:true,
      expense: true,
      ask_leave: true,
      roleForm: {
        roleid:"",
        organ_dep:"",
        organid: "",
        depid:"",
        rolename:"",
        queryRange: "1",
        state:"1",
        is_expense:"0",
        expense_num:"",
        is_ask_leave:"0",
        ask_leave_num:"",
        is_voucher:"0",
        voucher_num:"",
      },
      props:{
        label:'text',value:'value',children:'children',
        emitPath:false,
      },
      rules: {
        organ_dep: [{required: true, message: "请选择所属组织", trigger: "change"}],
        rolename: [{required: true, message: "请填写角色名称", trigger: "blur"}],
        queryRange: [{required: true, message: "请选择查询范围", trigger: "change"}],
      },
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.calcTableHeight()
      window.removeEventListener("resize", this.calcTableHeight)
      window.addEventListener("resize", this.calcTableHeight)
      this.loadData()
      this.loadRole()
    })
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.calcTableHeight)
  },
  methods: {
    calcTableHeight() {
      let mainHeight = window.getComputedStyle(this.$refs.mainSector.$el).height
      if (mainHeight.indexOf("px") !== -1)
        mainHeight = Number(mainHeight.substring(0, mainHeight.indexOf("px")))
      else
        mainHeight = Number(mainHeight)
      this.tableHeight = mainHeight - 120
    },
    toRefresh(){
      this.search.organid = ""
      this.search.depid = ""
      this.loadRole("refresh")
    },
    loadData () {
      this.$axios.get("/api/common/getorgantree" )
          .then((response) => {
            const data = response.data
            if (data.success) {
              this.treeData = data.result
              this.treeData.forEach(item => {
                if(item.type ==="company" && item.children.length === 0){
                  item["disabled"] = true
                }else{
                  item.children.forEach(i => {
                    if(i.type ==="company" && i.children.length === 0){
                      i["disabled"] = true
                    }
                  })
                }
              })
            } else {
              this.$alert(response.data.result, "获取组织列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取组织列表出错")
          })
    },
    /** 展开收缩 */
    toggleRowExpansion(){
      const nodes = this.$refs.organTree.store._getAllNodes();
      nodes.forEach(item=>{
        item.expanded = !item.expanded
      })
    },
    loadRole(d){
      this.loading = true
      this.$axios.post("/api/role/loadroleinfo", this.search)
          .then((response) => {
            if (response.data.success) {
              this.loading = false
              this.total = response.data.result.totalrecords
              this.roleData = response.data.result.datalist
              if(d === "refresh"){
                this.$message({message: "刷新成功", type: "success"})
              }
            }
            else {
              this.$alert(response.data.result, "获取角色列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取角色列表出错")
          })
    },
    toRoleDep(data){
      const parts = data.split('-');
      this.roleForm.organid = parts[0]
      this.roleForm.depid = parts[1]
    },
    toVoucher(data){
      if(data === "1"){
        this.voucher = false
      }else{
        this.roleForm.voucher_num = ""
        this.voucher = true
      }
    },
    toExpense(data){
      if(data === "1"){
        this.expense = false
      }else{
        this.roleForm.expense_num = ""
        this.expense = true
      }
    },
    toAskLeave(data){
      if(data === "1"){
        this.ask_leave = false
      }else{
        this.roleForm.ask_leave_num = ""
        this.ask_leave = true
      }
    },
    toAdd(){
      this.voucher = true
      this.expense = true
      this.ask_leave = true
      this.roleTitle = "新建"
      this.dailVisi = true
      this.roleForm.roleid = ""
      this.roleForm.organ_dep = ""
      this.roleForm.organid = ""
      this.roleForm.depid = ""
      this.roleForm.rolename = ""
      this.roleForm.queryRange ="1"
      this.roleForm.state = "1"
      this.roleForm.is_voucher = "0"
      this.roleForm.voucher_num = ""
      this.roleForm.is_expense = "0"
      this.roleForm.expense_num = ""
      this.roleForm.is_ask_leave = "0"
      this.roleForm.ask_leave_num = ""
      if (this.$refs.roleForm)
        this.$refs.roleForm.clearValidate()
    },
    toEdit(data){
      this.toAdd()
      this.roleForm.roleid = data.USER_ROLE
      this.roleForm.organ_dep = data.ORGAN_ID+ "-" +data.DEPARTMENT_ID
      this.roleForm.organid = data.ORGAN_ID
      this.roleForm.depid = data.DEPARTMENT_ID
      this.roleForm.rolename = data.ROLE_NAME
      this.roleForm.queryRange = data.QUERY_RANGE
      this.roleForm.is_voucher = data.IS_VOUCHER
      if(data.IS_VOUCHER === "1"){
        this.voucher = false
        this.roleForm.voucher_num = data.VOUCHER_NUM
      }
      this.roleForm.is_expense = data.IS_EXPENSE
      if(data.IS_EXPENSE === "1"){
        this.expense = false
        this.roleForm.expense_num = data.EXPENSE_NUM
      }
      this.roleForm.is_ask_leave = data.IS_ASK_LEAVE
      if(data.IS_ASK_LEAVE === "1"){
        this.ask_leave = false
        this.roleForm.ask_leave_num = data.ASK_LEAVE_NUM
      }
      this.roleTitle = "编辑"
      this.dailVisi = true
      if (this.$refs.roleForm)
        this.$refs.roleForm.clearValidate()
    },
    toSaveRole () {
      if(this.roleForm.is_expense === "1" && this.roleForm.expense_num === ""){
        this.$message({message: "报销审核顺序为空，无法保存", type: "error"})
      }
      if(this.roleForm.is_ask_leave === "1" && this.roleForm.ask_leave_num === ""){
        this.$message({message: "请假审核顺序为空，无法保存", type: "error"})
      }
      this.$refs["roleForm"].validate((valid) => {
        if (valid) {
          this.$axios.post("/api/role/saveroledata", this.roleForm)
              .then((response) => {
                if (response.data.success) {
                  this.$message({message: "角色保存成功", type: "success"})
                  this.dailVisi = false
                  this.loadRole()
                } else {
                  this.$alert(response.data.result, "角色保存出错")
                }
              })
              .catch((error) => {
                this.$alert(error, "角色保存出错")
              })
        }
        else
          return false
      })
    },

    toShowOrgan (node) {
      if(node.type === "department"){
        this.search.organid = node.ORGAN_ID
        this.search.depid = node.DEPARTMENT_ID
      }else{
        this.search.organid = node.ORGAN_ID
        this.search.depid = ""
      }
      this.loadRole()
    },

    switchState (data) {
      this.$axios.post("/api/role/switchrole", {roleid: data.USER_ROLE, state: data.STATE})
          .then((response) => {
            if (!response.data.success) {
              this.$alert(response.data.result, "公司状态切换出错")
              this.loadOrgan()
            }
          })
          .catch((error) => {
            this.$alert(error, "公司状态切换出错")
            this.loadOrgan()
          })
    },
    //删除
    toDel (data) {
      this.$confirm("确认删除此角色?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$axios.post("/api/role/delrole", {roleid: data.USER_ROLE})
            .then((response) => {
              if (response.data.success) {
                this.$message({message: "角色删除成功", type: "success"})
                this.loadRole()
              }
              else {
                this.$alert(response.data.result, "角色删除出错")
              }
            })
            .catch((error) => {
              this.$alert(error, "角色删除出错")
            })
      })
    },
  }
}
</script>

<style scoped>

</style>

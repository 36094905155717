<!--
组织管理
最后编辑人：张鹏
最后编辑时间：2023-6-16
最后编辑细节：
-->
<template>
  <el-container class="base-container">
    <el-header class="base-height">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>组织权限</el-breadcrumb-item>
        <el-breadcrumb-item>组织管理</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-container class="main-container" ref="mainSector">
      <el-main>
        <el-row>
          <el-col :span="4">
            <el-dropdown>
              <el-button type="success">
                <el-icon><Plus /></el-icon><span class="icon-right">新建</span>  <el-icon><ArrowDown /></el-icon>
              </el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click="addOrUpdateHandle('company')">新建公司</el-dropdown-item>
                  <el-dropdown-item @click="addOrUpdateHandle('department')">新建部门</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </el-col>
          <el-col :span="6" class="input-search">
            <el-input v-model="search.organ_name" placeholder="请输入公司名称" @keyup.enter="loadOrgan" @clear="loadOrgan" clearable size="default"></el-input>
          </el-col>
          <el-col :span="9">
            <el-button
                type="primary"
                size="default"
                @click="loadOrgan">
              <el-icon><Search /></el-icon> <span class="icon-right">查询</span>
            </el-button>
            <el-button
                size="default"
                @click="toReset">
              <el-icon><RefreshRight /></el-icon><span class="icon-right">重置</span>
            </el-button>
          </el-col>
          <el-col :span="5" style="text-align: right;">
            <el-tooltip v-if="openFlag" effect="dark" content="收起" placement="top" >
              <el-link  :underline="false" type="text" @click="toggleRowExpansion(openFlag)">
                <i class="iconfont icon-down"></i>
              </el-link>
            </el-tooltip>
            <el-tooltip v-else effect="dark" content="展开" placement="top">
              <el-link :underline="false" type="text" @click="toggleRowExpansion(openFlag)">
                <i class="iconfont icon-zhedie"></i>
              </el-link>
            </el-tooltip>
            <el-tooltip effect="dark" content="刷新" placement="top">
              <el-link :underline="false" @click="loadOrgan('refresh')">
                <el-icon :size="20"><Refresh /></el-icon>
              </el-link>
            </el-tooltip>

          </el-col>
        </el-row>
        <div style="padding:5px;">
          <el-table
              row-key="id"
              ref="roleList"
              :height="tableHeight"
              :data="organListData"
              :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
              style="width: 100%"
              @expand-change="expandChange"
              default-expand-all
              v-loading="loading">
            <template v-slot:empty>
              <el-empty description="暂无数据" :image-size="120"></el-empty>
            </template>
            <el-table-column prop="name" label="名称"  show-overflow-tooltip>
              <template #default="scope">
                <i class="iconfont icon-zuzhiguanli" style="font-size: 18px;" v-if="scope.row.type ==='1'"></i>
                <i class="iconfont icon-tree-department-1" style="font-size: 18px;" v-if="scope.row.type ==='2'"></i>
                {{ scope.row.name }}
              </template>
            </el-table-column>
            <el-table-column prop="level" label="层级" show-overflow-tooltip align="center" />
            <el-table-column prop="typeName" label="类型" align="center" />
            <el-table-column prop="CREATE_TIME" label="创建时间" align="center" />
            <el-table-column
                label="状态"
                fixed="right"
                align="center">
              <template #default="scope">
                <el-switch
                    v-model="scope.row.STATE"
                    active-value="1"
                    inactive-value="0"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    inline-prompt
                    active-text="启"
                    inactive-text="禁"
                    @change="switchState(scope.row)">
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                align="center"
                min-width="50px"
                max-width="60px">
              <template #default="scope">
                <el-button class="table-button"
                           size="small"
                           type="primary"
                           @click="toEdit(scope.row)">编辑</el-button>
                <el-button class="table-button"
                           size="small"
                           type="danger"
                           @click="toDel(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <el-dialog
            width="1000px"
            :title="OrganTitle"
            v-model="dailVisi"
            :close-on-click-modal="false"
            :close-on-press-escape="false">
          <div style="height: calc(100% - 100px); overflow: auto;">
            <el-form
                :inline="true"
                :model="organData"
                :rules="rules"
                ref="organForm"
                label-width="130px"
            >
              <el-form-item label="上级公司:" prop="belongorganid" class="form-row">
                <el-select v-model="organData.belongorganid" placeholder="添加总公司时，此为空" style="width: 100%;">
                  <el-option
                      v-for="item in organTree"
                      :key="item.ORGAN_ID"
                      :label="item.ORGAN_NAME"
                      :value="item.ORGAN_ID">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="公司名称:" prop="organname" class="form-col">
                <el-input v-model="organData.organname" />
              </el-form-item>
              <el-form-item label="公司简称:" prop="abbreviation" class="form-col">
                <el-input v-model="organData.abbreviation" />
              </el-form-item>
              <el-form-item label="公司电话:" prop="phone" class="form-col">
                <el-input v-model="organData.phone" />
              </el-form-item>
              <el-form-item label="公司性质:" prop="nature" class="form-col">
                <el-input v-model="organData.nature" />
              </el-form-item>
              <el-form-item label="公司地址:" prop="address" class="form-row">
                <el-input  type="textarea" v-model="organData.address" />
              </el-form-item>
              <el-form-item label="备注:" prop="notes" class="form-row">
                <el-input  type="textarea" v-model="organData.notes" />
              </el-form-item>
            </el-form>
            <div class="div-button">
              <el-button type="primary" @click="toSave('company')">保 存</el-button>
            </div>
          </div>
        </el-dialog>

        <el-dialog
            width="1000px"
            :title="depTitle"
            v-model="dailVisi1"
            :close-on-click-modal="false"
            :close-on-press-escape="false">
          <div style="height: calc(100% - 100px); overflow: auto;">
            <el-form
                :inline="true"
                :model="depData"
                :rules="rules1"
                ref="depForm"
                label-width="130px"
            >
              <el-form-item label="所属公司:" prop="organid" class="form-col">
                <el-cascader
                    placeholder="请选择所属公司"
                    style="width: 100%;"
                    v-model="depData.organid"
                    :props="props"
                    clearable
                    :options="organTree" >
                </el-cascader>
              </el-form-item>
              <el-form-item label="部门名称:" prop="depname" class="form-col">
                <el-input v-model="depData.depname" />
              </el-form-item>
              <el-form-item label="备注:" prop="notes" class="form-row">
                <el-input  type="textarea" v-model="depData.notes" />
              </el-form-item>
            </el-form>
            <div class="div-button">
              <el-button type="primary" @click="toSave('department')">保 存</el-button>
            </div>
          </div>
        </el-dialog>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>


export default {
  name: "organMge",
  data() {
    return {
      search: {
        organ_name:"",
      },
      indexList:[],
      tableHeight: 0,
      organListData:[],
      loading:false,
      openFlag:true,
      OrganTitle: "公司新增",
      dailVisi: false,
      organData: {
        belongorganid: null,
        organid: "",
        organname:"",
        abbreviation:"",
        phone:"",
        nature:"",
        address:"",
        notes:"",
      },
      organTree:[],
      props:{
        label:'name',value:'ORGAN_ID',children:'children',
        emitPath:false,
        checkStrictly: true,
      },
      rules: {
        organname: [{required: true, message: "请填写公司名称", trigger: "blur"}, {max: 255, message: "不能超过250个字", trigger: "blur"}],
      },
      depTitle: "部门新增",
      dailVisi1: false,
      depData: {
        depid:"",
        organid: "",
        depname:"",
        notes:"",
      },
      rules1: {
        depname: [{required: true, message: "请填写部门名称", trigger: "blur"}, {max: 255, message: "不能超过250个字", trigger: "blur"}],
        organid: [{required: true, message: "请选择公司名称", trigger: "blur"}],
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.calcTableHeight()
      window.removeEventListener("resize", this.calcTableHeight)
      window.addEventListener("resize", this.calcTableHeight)
      this.loadOrgan()
    })
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.calcTableHeight)
  },
  methods: {
    calcTableHeight() {
      let mainHeight = window.getComputedStyle(this.$refs.mainSector.$el).height
      if (mainHeight.indexOf("px") !== -1)
        mainHeight = Number(mainHeight.substring(0, mainHeight.indexOf("px")))
      else
        mainHeight = Number(mainHeight)
      this.tableHeight = mainHeight - 120
    },
    /** 判断是否展开收缩 */
    expandChange(row, expandedRows ){
      if(expandedRows){
        this.openFlag = true
      }else{
        this.openFlag = false
      }
    },
    /** 展开收缩 */
    toggleRowExpansion(isExpansion){
      this.openFlag = !isExpansion;
      this.toggleRowExpansion_forAll(this.organListData,this.openFlag);
    },
    toggleRowExpansion_forAll(data,isExpansion){
      data.forEach(item=>{
        this.$refs.roleList.toggleRowExpansion(item,isExpansion);
        if(item.children !== undefined && item.children != null){
          this.toggleRowExpansion_forAll(item.children,isExpansion);
        }
      })
    },
    loadOrgan(d) {
      this.loading = true
      var that = this
      this.$axios.post("/api/organ/loadorgan", this.search)
          .then((response) => {
            if (response.data.success) {
              that.loading = false
              this.organListData = response.data.result
              if(d === "refresh"){
                this.$message({message: "刷新成功", type: "success"})
              }
            } else {
              this.$alert(response.data.result, "查询组织列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "查询组织列表出错")
          })

      this.$axios.post("/api/organ/loadorgantree", this.search)
          .then((response) => {
            if (response.data.success) {
              this.organTree = response.data.result
            } else {
              this.$alert(response.data.result, "查询公司列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "查询公司列表出错")
          })
    },
    toReset() {
      this.search.organ_name = ""
      this.loadOrgan()
    },

    addOrUpdateHandle (type) {
      if (type === 'company') {
        this.addOrUpdateOrganize()
      } else {
        this.addOrUpdateDep()
      }
    },
    addOrUpdateOrganize(){
      this.dailVisi = true
      this.organData.belongorganid = null
      this.organData.organid = ""
      this.organData.organname = ""
      this.organData.abbreviation = ""
      this.organData.phone = ""
      this.organData.nature = ""
      this.organData.address = ""
      this.organData.notes = ""
      if (this.$refs.organForm)
        this.$refs.organForm.clearValidate()
    },
    addOrUpdateDep(){
        this.dailVisi1 = true
        this.depData.depid = ""
        this.depData.organid = ""
        this.depData.depname = ""
        this.depData.notes = ""
        if (this.$refs.depForm)
          this.$refs.depForm.clearValidate()
    },

    toEdit (data){
      if(data.type === '1'){
        this.organData.belongorganid = data.BELONG_ORGAN_ID
        this.organData.organid = data.ORGAN_ID
        this.organData.organname = data.ORGAN_NAME
        this.organData.abbreviation = data.ABBREVIATION
        this.organData.phone = data.PHONE
        this.organData.nature = data.ORGAN_NATURE
        this.organData.address = data.ADDRESS
        this.organData.notes = data.NOTES
        this.OrganTitle = "公司编辑"
        this.dailVisi = true
        if (this.$refs.organForm)
          this.$refs.organForm.clearValidate()
      }else{
        this.depData.depid = data.DEPARTMENT_ID
        this.depData.organid = data.ORGAN_ID
        this.depData.depname = data.DEPARTMENT_NAME
        this.depData.notes = data.NOTES
        this.depTitle = "部门编辑"
        this.dailVisi1 = true
        if (this.$refs.depForm)
          this.$refs.depForm.clearValidate()
      }

    },

    toSave(type) {
      if(type === 'company'){
        this.$refs["organForm"].validate((valid) => {
          if (valid) {
            this.$axios.post("/api/organ/saveorgandata", this.organData)
                .then((response) => {
                  if (response.data.success) {
                    this.$message({message: "公司保存成功", type: "success"})
                    this.dailVisi = false
                    this.loadOrgan()
                  } else {
                    this.$alert(response.data.result, "公司保存出错")
                  }
                })
                .catch((error) => {
                  this.$alert(error, "公司保存出错")
                })
          }
          else
            return false
        })
      }else{
        this.$refs["depForm"].validate((valid) => {
          if (valid) {
            this.$axios.post("/api/organ/savedepdata", this.depData)
                .then((response) => {
                  if (response.data.success) {
                    this.$message({message: "部门保存成功", type: "success"})
                    this.dailVisi1 = false
                    this.loadOrgan()
                  } else {
                    this.$alert(response.data.result, "部门保存出错")
                  }
                })
                .catch((error) => {
                  this.$alert(error, "部门保存出错")
                })
          }
          else
            return false
        })
      }

    },
    //删除
    toDel (data) {
      if(data.type === '1') {
        this.$confirm("确认删除此公司及下属全部部门吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          this.$axios.post("/api/organ/delorgan", {organid: data.ORGAN_ID})
              .then((response) => {
                if (response.data.success) {
                  this.$message({message: "公司删除成功", type: "success"})
                  this.loadOrgan()
                } else {
                  this.$alert(response.data.result, "公司删除出错")
                }
              })
              .catch((error) => {
                this.$alert(error, "公司删除出错")
              })
        })
      }else{
        this.$confirm("确认删除此部门吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          this.$axios.post("/api/organ/deldepartment", {organid: data.ORGAN_ID, depid:data.DEPARTMENT_ID})
              .then((response) => {
                if (response.data.success) {
                  this.$message({message: "部门删除成功", type: "success"})
                  this.loadOrgan()
                } else {
                  this.$alert(response.data.result, "部门删除出错")
                }
              })
              .catch((error) => {
                this.$alert(error, "部门删除出错")
              })
        })
      }
    },

    //切换状态
    switchState (data) {
      if(data.level === '1'){
        this.$axios.post("/api/organ/switchorgan", {organid: data.ORGAN_ID, state: data.STATE})
            .then((response) => {
              if (!response.data.success) {
                this.$alert(response.data.result, "公司状态切换出错")
              }
            })
            .catch((error) => {
              this.$alert(error, "公司状态切换出错")
            })
        setTimeout(this.loadOrgan, 300)
      }else{
        this.$axios.post("/api/organ/switchdep", {depid: data.DEPARTMENT_ID, state: data.STATE})
            .then((response) => {
              if (!response.data.success) {
                this.$alert(response.data.result, "部门状态切换出错")
                this.loadOrgan()
              }
            })
            .catch((error) => {
              this.loadOrgan()
              this.$alert(error, "部门状态切换出错")
            })
      }

    },
  }
}
</script>

<style scoped>

:deep(.el-upload--picture-card){
  width: 100px;
  height: 100px;
}
:deep(.el-upload){
  width: 100px;
  height: 100px;
  line-height: 100px;
}
:deep(.el-upload-list--picture-card .el-upload-list__item){
  width: 100px;
  height: 100px;
  line-height: 100px;
}
:deep(.el-upload-list--picture-card .el-upload-list__item-thumbnail){
  width: 100px;
  height: 100px;
  line-height: 100px;
}
:deep(.avatar){
  width: 100px;
  height: 100px;
}

.margin-top{
  margin-top: 10px;
}

</style>

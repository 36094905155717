<template>
  <el-container class="base-container">
    <el-header class="base-height">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>数据管理</el-breadcrumb-item>
        <el-breadcrumb-item>科目管理</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <Loading ref="loading"></Loading>
    <el-container class="main-container" ref="mainSector">
      <el-aside width="70%" style="padding: 20px 5px 0 20px;">
        <el-row>
          <el-col :span="9" >
            <el-button type="success" @click="toAddSubject()" style="margin-right: 10px;" v-if="this.userRole ==='0'">
              <el-icon><Plus /></el-icon><span class="icon-right">新建</span>
            </el-button>
            <el-dropdown v-if="this.userRole ==='0'">
              <el-button type="primary" >
                <i class="iconfont icon-daoru" style="font-size: 14px"></i><span>导入</span>  <el-icon><ArrowDown /></el-icon>
              </el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item>
                    <el-link :href="href" target="_blank" type="primary" :underline="false" >
                      <el-icon><Download /></el-icon>下载模板
                    </el-link>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-upload
                        ref="uploadRef"
                        :action="uploadAction"
                        name="uploadfile"
                        :before-upload="beforeUpload"
                        :on-progress="uploadProgress"
                        :on-success="uploadSuccess"
                        :file-list="fileList"
                        :show-file-list="false"
                        :with-credentials="true"
                        :disabled="showProgress">
                      <div>
                        <el-tooltip effect="light" content="只能上传Excel(xls/xlsx)文件，且不超过100MB" placement="right">
                          <el-link target="_blank" type="warning" :underline="false" >
                            <el-icon><Upload /></el-icon>导入数据
                          </el-link>
                        </el-tooltip>
                      </div>
                    </el-upload>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <el-button type="primary" @click="doExport" :loading="exportTip !== ''" style="margin-left: 10px;" v-if="this.userRole ==='0'">
              <span><i class="iconfont icon-daochu" style="font-size: 15px"></i>导出</span>
            </el-button>
            <a ref="expLink" target="_blank" style="display: none;"></a>
          </el-col>
          <el-col :span="5" class="input-search">
            <el-input v-model="search.subjectName" placeholder="请输入科目名称" @keyup.enter="loadSubject" @clear="loadSubject" clearable size="default"></el-input>
          </el-col>
          <el-col :span="5" class="input-search">
            <el-select v-model="search.subjectType" placeholder="请选择科目类别" @change="loadSubject()" clearable>
              <el-option
                  v-for="item in subjectTypeData"
                  :key="item.CODE"
                  :label="item.CODE_NAME"
                  :value="item.CODE">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-button
                type="primary"
                size="default"
                @click="loadSubject">查询</el-button>
            <el-button
                size="default"
                @click="toReset">重置</el-button>
          </el-col>
          <el-col :span="1" style="text-align: right;">
            <el-tooltip effect="dark" content="刷新" placement="top">
              <el-link :underline="false" @click="toRefresh()">
                <el-icon :size="20"><Refresh /></el-icon>
              </el-link>
            </el-tooltip>
          </el-col>
        </el-row>
        <div style="line-height: 20px; margin-top: 5px">
          <div v-if="elProgress" style="display: flex;flex-direction: row; align-items: center">
            <div style="width: 80px">
              <span style="font-size: 12px;">{{ progressInfo.text }}</span>
            </div>
            <div style="width: 90%">
              <el-progress :percentage="progressPercent"/>
            </div>
          </div>
          <div v-if="exportTip !== ''">
            <span style="font-size: 12px; margin-left: 80px">{{ exportTip }}</span>
          </div>
        </div>
        <div>
          <el-table
              :height="tableHeight"
              :data="subjectList"
              ref="subjectRef"
              @row-click="doSubject"
              @select-all="handleSelectAll"
              @select="handleSelectionChange">
            <el-table-column type="selection" width="55" />
            <el-table-column
                prop="SUBJECT_SORT"
                label="编号"
                show-overflow-tooltip
                min-width="70px"
                align="center"
            >
            </el-table-column>
            <el-table-column
                prop="SUBJECT_NAME"
                label="科目名称"
                min-width="150px"
                show-overflow-tooltip
                align="center"
            >
            </el-table-column>
            <el-table-column
                prop="SUBJECT_TYPE_NAME"
                label="科目类别"
                min-width="80px"
                show-overflow-tooltip
                align="center"
            >
            </el-table-column>
            <el-table-column
                prop="SUBJECT_NATURE_NAME"
                label="科目性质"
                min-width="80px"
                show-overflow-tooltip
                align="center"
                v-if="this.userRole ==='0'"
            >
            </el-table-column>
            <el-table-column
                prop="FLOW_TYPE_NAME"
                label="流动分类"
                min-width="80px"
                show-overflow-tooltip
                align="center"
                v-if="this.userRole ==='0'"
            >
            </el-table-column>
            <el-table-column
                prop="REPORT_TYPE_NAME"
                label="报表分类"
                min-width="80px"
                show-overflow-tooltip
                align="center"
                v-if="this.userRole ==='0'"
            >
            </el-table-column>

            <el-table-column
                prop="REPORT_CLASS_NAME"
                label="报表类别"
                min-width="120px"
                show-overflow-tooltip
                align="center"
                v-if="this.userRole ==='0'"
            >
            </el-table-column>
            <el-table-column
                prop="REPORT_PROJECT"
                label="报表项目"
                min-width="120px"
                show-overflow-tooltip
                align="center"
                v-if="this.userRole ==='0'"
            >
            </el-table-column>
            <el-table-column
                prop="CASH_FLOW_REV"
                label="现金流量（收入）"
                min-width="80px"
                show-overflow-tooltip
                align="center"
                v-if="this.userRole ==='0'"
            >
            </el-table-column>
            <el-table-column
                prop="CASH_FLOW_EXP"
                label="现金流量（支出）"
                min-width="80px"
                show-overflow-tooltip
                align="center"
                v-if="this.userRole ==='0'"
            >
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                width="150px"
                v-if="this.userRole ==='0'"
                align="center">
              <template #default="scope">
                <el-button class="table-button"
                           size="small"
                           type="primary"
                           @click="toEdit(scope.row)">编辑</el-button>
                <el-button class="table-button"
                           size="small"
                           type="danger"
                           @click="toDel(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
              background
              v-model:current-page="search.page"
              v-model:page-size="search.pagesize"
              layout="total, sizes, prev, pager, next, jumper"
              @current-change="loadSubject"
              @size-change="loadSubject"
              :page-sizes="[50, 100, 200]"
              :total="total"
              style="margin-top: 10px;"
          >
          </el-pagination>
        </div>
      </el-aside>

      <el-main style="height: 100%; border-left: 10px solid #f5f5f5;">
        <div style="height: 20px">
          <span>{{ this.detailTital }}</span>
          <el-button type="success" @click="toAddDetail()" size="small" style="float:right;">
            <el-icon><Plus /></el-icon>
          </el-button>
        </div>
        <el-divider style="margin: 15px 0;"/>
        <div style="height: calc(100% - 250px);">
          <el-table
              :data="detailList"
              style="height: calc(100% - 30px);">
            <el-table-column
                prop="DETAIL_SORT"
                label="序号"
                show-overflow-tooltip
                min-width="60px"
                align="center"
            >
            </el-table-column>
            <el-table-column
                prop="SUBJECT_DETAIL"
                label="科目明细"
                min-width="150px"
                show-overflow-tooltip
                align="center"
            >
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                align="center">
              <template #default="scope">
                <el-button-group>
                  <el-button class="table-button"
                             size="small"
                             type="primary"
                             @click="toEditDetail(scope.row)">
                    <el-icon><Edit /></el-icon>
                  </el-button>
                  <el-button class="table-button"
                             size="small"
                             type="danger"
                             @click="toDelDetail(scope.row)">
                    <el-icon><Delete /></el-icon>
                  </el-button>
                </el-button-group>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="height: 150px;">
          <el-form :model="detailData" :rules="rules1" ref="subjectDetailForm" label-width="100px">
            <el-form-item label="科目名称" prop="subjectName">
              <el-input v-model="detailData.subjectName" disabled></el-input>
            </el-form-item>
            <el-form-item label="明细编号" prop="subjectDetail">
              <el-input v-model="detailData.detailSort" :disabled="unedit"></el-input>
            </el-form-item>
            <el-form-item label="科目明细" prop="subjectDetail">
              <el-input v-model="detailData.subjectDetail" :disabled="unedit"></el-input>
            </el-form-item>
            <div class="div-button">
              <el-button type="success" @click="toSaveDetail" :disabled="unedit">保 存</el-button>
            </div>
          </el-form>
        </div>
      </el-main>

      <el-dialog
          width="1000px"
          :title="SubjectTitle"
          v-model="dailVisi"
          :close-on-click-modal="false"
          :close-on-press-escape="false">
        <div style="height: calc(100% - 100px); overflow: auto;">
          <el-form
              :inline="true"
              :model="subjectData"
              :rules="rules"
              ref="subjectForm"
              label-width="130px"
          >
            <el-form-item label="科目名称:" prop="subjectname" class="form-row">
              <el-input v-model="subjectData.subjectname" />
            </el-form-item>
            <el-form-item label="科目类别" prop="subjecttype" class="form-col">
              <el-select v-model="subjectData.subjecttype" placeholder="请选择" style="width: 100%;">
                <el-option
                    v-for="item in subjectTypeData"
                    :key="item.CODE"
                    :label="item.CODE_NAME"
                    :value="item.CODE">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="编号:" prop="sort" class="form-col">
              <el-input v-model="subjectData.sort" disabled/>
            </el-form-item>
            <el-form-item label="科目性质" prop="subjectNature" class="form-col">
              <el-select v-model="subjectData.subjectNature" placeholder="请选择" style="width: 100%;">
                <el-option
                    v-for="item in subjectNatureData"
                    :key="item.CODE"
                    :label="item.CODE_NAME"
                    :value="item.CODE">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="流动分类" prop="flowType" class="form-col">
              <el-select v-model="subjectData.flowType" placeholder="请选择" style="width: 100%;">
                <el-option
                    v-for="item in flowTypeData"
                    :key="item.CODE"
                    :label="item.CODE_NAME"
                    :value="item.CODE">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="报表分类" prop="reportType" class="form-col">
              <el-select v-model="subjectData.reportType" placeholder="请选择" style="width: 100%;">
                <el-option
                    v-for="item in reportTypeData"
                    :key="item.CODE"
                    :label="item.CODE_NAME"
                    :value="item.CODE">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="类别代码:" prop="typeCode" class="form-col">
              <el-input v-model="subjectData.typeCode"/>
            </el-form-item>
            <el-form-item label="报表类别:" prop="reportClass" class="form-col">
              <el-select v-model="subjectData.reportClass" placeholder="请选择" style="width: 100%;" v-if="this.subjectData.reportType !== '3'">
                <el-option
                    v-for="item in reportClassData"
                    :key="item.CODE"
                    :label="item.CODE_NAME"
                    :value="item.CODE">
                </el-option>
              </el-select>
              <el-input v-model="subjectData.reportClass" v-else/>
            </el-form-item>
            <el-form-item label="报表项目:" prop="reportProject" class="form-col">
              <el-input v-model="subjectData.reportProject"/>
            </el-form-item>
            <el-form-item label="现金流量（收入）:" prop="cashFlowRev" class="form-col">
              <el-input v-model="subjectData.cashFlowRev"/>
            </el-form-item>
            <el-form-item label="现金流量（支出）:" prop="cashFlowExp" class="form-col">
              <el-input v-model="subjectData.cashFlowExp"/>
            </el-form-item>
          </el-form>
          <div class="div-button">
            <el-button type="primary" @click="toSave">保 存</el-button>
          </div>
        </div>
      </el-dialog>
    </el-container>
  </el-container>
</template>

<script>
import Loading from '@/common/LoadingPage'
import apiURL from "../apiUrl/api.js";
export default {
  name: "SubjectMge",
  components:{ Loading },
  data () {
    return {
      href: apiURL + "/api/subject/downtowntemplate",
      uploadAction: apiURL +"/api/subject/importtemplate",
      userRole: sessionStorage.getItem('organ-role').toString(),
      tableHeight: 0,
      subjectTypeData: [],
      subjectNatureData: [],
      flowTypeData: [],
      reportTypeData: [],
      reportClassData:[],
      search: {
        subjectName:"",
        subjectType:"",
        page: 1,
        pagesize: 50,
      },
      SubjectTitle: "",
      dailVisi: false,
      subjectList:[],
      total: 0,
      subjectData: {
        subjectid:"",
        subjectname:"",
        subjecttype: "",
        sort:"",
        subjectNature:"",
        flowType:"",
        reportType:"",
        typeCode:"",
        reportClass:"",
        reportProject:"",
        cashFlowRev:"",
        cashFlowExp:"",
      },
      fileList:[],
      showProgress: false,
      progressPercent: 0,
      elProgress: false,
      progressInfo: {
        state: "1",
        text: "",
      },
      exportTip: "",
      rules: {
        subjectname: [{required: true, message: "请填写科目名称", trigger: "blur"}],
        subjecttype: [{required: true, message: "请选择科目类别", trigger: "blur"}],
        sort: [{required: true, message: "请填写排序", trigger: "blur"}],
      },
      detailData:{
        subjectDetailId:"",
        subjectSort:"",
        subjectName:"",
        detailSort:"",
        subjectDetail:"",
      },
      detailTital:"",
      unedit:true,
      detailList:[],
      subjectAllList:[],
      rules1: {
        subjectDetail: [{required: true, message: "请填写科目明细", trigger: "blur"}],
      },
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.calcTableHeight()
      window.removeEventListener("resize", this.calcTableHeight)
      window.addEventListener("resize", this.calcTableHeight)
      this.loadCode()
      let name = this.$route.query.name;
      if(name){
        this.search.subjectName = name
        this.loadSubject()
        this.$router.replace({ path: this.$route.path });
      }else{
        this.search.subjectName = ""
        this.loadSubject()
      }
    })
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.calcTableHeight)
  },
  methods: {
    calcTableHeight() {
      let mainHeight = window.getComputedStyle(this.$refs.mainSector.$el).height
      if (mainHeight.indexOf("px") !== -1)
        mainHeight = Number(mainHeight.substring(0, mainHeight.indexOf("px")))
      else
        mainHeight = Number(mainHeight)
      this.tableHeight = mainHeight - 120
    },
    loadCode() {
      this.$axios.post("/api/common/getcodelists", {codes: [ "SUBJECT_TYPE", "SUBJECT_NATURE", "FLOW_TYPE", "REPORT_TYPE", "REPORT_CLASS"]})
          .then((response) => {
            const data = response.data
            if (data.success) {
              this.subjectTypeData = data.result.SUBJECT_TYPE
              this.subjectNatureData = data.result.SUBJECT_NATURE
              this.flowTypeData = data.result.FLOW_TYPE
              this.reportTypeData = data.result.REPORT_TYPE
              this.reportClassData = data.result.REPORT_CLASS
            } else {
              this.$alert(response.data.result, "获取代码列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取代码列表出错")
          })
    },
    toRefresh() {
      this.search.subjectName = ""
      this.loadSubject("refresh")
    },

    loadSubject(d) {
      this.$axios.post("/api/subject/loadsubjectinfo", this.search)
          .then((response) => {
            if (response.data.success) {
              this.total = response.data.result.totalrecords
              this.subjectList = response.data.result.datalist
              this.loadIsFrequent()
              if(d === "refresh"){
                if(this.detailData.subjectSort !== ""){
                  let f = this.subjectList.filter((item) => {
                    return Number(item.SUBJECT_SORT) === Number(this.detailData.subjectSort)
                  })
                  this.detailList = f[0].detailList
                }else{
                  this.$message({message: "刷新成功", type: "success"})
                }
              }
              if(d === "upload"){
                this.$message({message: "上传成功", type: "success"})
              }
            }
            else {
              this.$alert(response.data.result, "获取科目列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取科目列表出错")
          })
    },

    loadIsFrequent () {
      this.$axios.get("/api/subject/loadisfrequent")
          .then((response) => {
            if (response.data.success) {
              let checkedKeys = []
              if(response.data.result != null && response.data.result.SUBJECT_ID_LIST !== "null" && response.data.result.SUBJECT_ID_LIST !== ""){
                checkedKeys = JSON.parse(response.data.result.SUBJECT_ID_LIST)
                checkedKeys.forEach(bat => {
                  this.subjectList.forEach(w => {
                    if (w.SUBJECT_ID === bat)
                      this.$refs.subjectRef.toggleRowSelection(w)
                  })
                })
              }
            }
            else {
              this.$alert(response.data.result, "获取科目使用状态出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取科目使用状态出错")
          })
    },

    //处理表格勾选
    handleSelectionChange (multi, row) {
      this.$refs.loading.show()
      multi.forEach(bat => {
        if(bat.SUBJECT_ID === row.SUBJECT_ID){
          row["use"] = true
        }else {
          row["use"] = false
        }
      })
      this.$axios.post("/api/subject/switchwish", row)
          .then((response) => {
            if (response.data.success) {
              this.$refs.loading.hide()
              if(row["use"])
                this.$message({message: "设置常用成功", type: "success"})
              else
                this.$message({message: "设置不常用成功", type: "warning"})
            }else{
              this.$refs.loading.hide()
              this.$alert(response.data.result, "勾选常用出错")
              this.isLoading = false
            }
          })
          .catch((error) => {
            this.$refs.loading.hide()
            this.$alert(error, "勾选常用出错")
          })
    },

    //处理表格勾选
    handleSelectAll () {
      this.$refs.loading.show()
      this.$axios.post("/api/subject/switchSelectAll", this.search)
          .then((response) => {
            if (response.data.success) {
              this.$refs.loading.hide()
              this.$message({message: "全部设置常用成功", type: "success"})
            }else{
              this.$refs.loading.hide()
              this.$alert(response.data.result, "勾选常用出错")
              this.isLoading = false
            }
          })
          .catch((error) => {
            this.$refs.loading.hide()
            this.$alert(error, "勾选常用出错")
          })
    },
    //选择科目类别后自动获取排序
    getSort(){
      this.subjectData.sort = parseInt(this.total)+1
    },
    toReset() {
      this.search.subjectName = ""
      this.loadSubject()
    },

    toAddSubject () {
      this.SubjectTitle = "科目新增"
      this.dailVisi = true
      this.subjectData.subjectid = ""
      this.subjectData.subjectname = ""
      this.subjectData.subjecttype = ""
      this.subjectData.sort = ""
      this.getSort()
      this.subjectData.subjectNature = ""
      this.subjectData.flowType = ""
      this.subjectData.reportType = ""
      this.subjectData.typeCode = ""
      this.subjectData.reportClass = ""
      this.subjectData.reportProject = ""
      this.subjectData.cashFlowRev = ""
      this.subjectData.cashFlowExp = ""
      if (this.$refs.subjectForm)
        this.$refs.subjectForm.clearValidate()
    },

    toEdit (data){
      this.subjectData.subjectid = data.SUBJECT_ID
      this.subjectData.subjectname = data.SUBJECT_NAME
      this.subjectData.subjecttype = data.SUBJECT_TYPE
      this.subjectData.sort = data.SUBJECT_SORT
      this.subjectData.subjectNature = data.SUBJECT_NATURE
      this.subjectData.flowType = data.FLOW_TYPE
      this.subjectData.reportType = data.REPORT_TYPE
      this.subjectData.typeCode = data.TYPE_CODE
      this.subjectData.reportClass = data.REPORT_CLASS
      this.subjectData.reportProject = data.REPORT_PROJECT
      this.subjectData.cashFlowRev = data.CASH_FLOW_REV
      this.subjectData.cashFlowExp = data.CASH_FLOW_EXP
      this.SubjectTitle = "科目编辑"
      this.dailVisi = true
      if (this.$refs.subjectForm)
        this.$refs.subjectForm.clearValidate()
    },

    toSave() {
      this.$refs["subjectForm"].validate((valid) => {
        if (valid) {
          this.$axios.post("/api/subject/savesubjectdata", this.subjectData)
              .then((response) => {
                if (response.data.success) {
                  this.$message({message: "科目保存成功", type: "success"})
                  this.dailVisi = false
                  this.loadSubject()
                } else {
                  this.$alert(response.data.result, "科目保存出错")
                }
              })
              .catch((error) => {
                this.$alert(error, "科目保存出错")
              })
        }
        else
          return false
      })
    },
    //删除
    toDel (data) {
      this.$confirm("确认删除此会计科目?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$axios.post("/api/subject/delsubject", {subjectid: data.SUBJECT_ID})
            .then((response) => {
              if (response.data.success) {
                this.$message({message: "科目删除成功", type: "success"})
                this.loadSubject()
              }
              else {
                this.$alert(response.data.result, "科目删除出错")
              }
            })
            .catch((error) => {
              this.$alert(error, "科目删除出错")
            })
      })
    },
    //上传前判断是否超过大小限制
    beforeUpload (file) {
      const isOut = file.size / 1024 / 1024 < 100
      if (!isOut) {
        this.$message.error("所选文件大小超过了100MB")
        return false
      }
      const suffix = file.name.indexOf(".") > -1 ? file.name.substring(file.name.lastIndexOf(".")).toLowerCase() : ""
      if (suffix !== ".xls" && suffix !== ".xlsx") {
        this.$message.error("只能上传Excel文件")
        return false
      }
      this.showProgress = true
      this.progressPercent = 0
      this.elProgress = true
      this.timer = setInterval(this.getProgress, 1500)
    },
    //显示上传进度
    uploadProgress (event) {
      console.log(event)
    },

    //上传成功
    uploadSuccess (res) {
      if (res.success) {
        this.progressPercent = 100
        this.fileList = []
        this.loadSubject("upload")
      }else {
        if (typeof res.result === "string")
          this.$alert(res.result, "上传数据出错")
        else {
          var _this = this
          res.result.forEach( item =>{
            _this.$message({message: item, type: "error", showClose: true, duration:8000, position: "bottom-right"})
          })
        }
      }
      clearInterval(this.timer)
      this.elProgress = false
      this.showProgress = false
    },

    //定时获取进度
    getProgress () {
      this.$axios.get("/api/subject/getimportprogress")
          .then((response) => {
            const data = response.data
            if (data.success) {
              if (data.result.state != null) {
                this.progressPercent = data.result.cur
                this.progressInfo.state = data.result.state
                this.progressInfo.text = data.result.state === "2" ? "导入完成" : data.result.msg
                if (data.result.state === "2") {
                  if (this.timer != null)
                    clearInterval(this.timer)
                  this.showProgress = false
                  this.progressPercent = 0
                  this.progressInfo.text = ""
                }
              }
            }
            else {
              this.$alert(data.result, "获取导入进度出错")
              if (this.timer != null)
                clearInterval(this.timer)
              this.showProgress = false
              this.progressInfo.state = ""
              this.progressInfo.text = ""
            }
          })
          .catch((error) => {
            this.$alert(error, "获取导入进度出错")
            this.showProgress = false
            this.progressInfo.state = ""
            this.progressInfo.text = ""
          })
    },

    doSubject(row){
      this.detailList = row.detailList
      this.detailTital = row.SUBJECT_NAME
      this.detailData.subjectSort = row.SUBJECT_SORT
      this.detailData.subjectName = row.SUBJECT_NAME
      this.detailData.detailSort = ""
      this.detailData.subjectDetail = ""
      this.detailData.subjectDetailId = ""
    },
    toAddDetail(){
      this.unedit = false
      if (typeof this.detailList !== "undefined") {
        let sort = 1
        const sub = this.detailList
        sub.forEach(perm => {
          if (parseInt(perm.DETAIL_SORT) >= sort)
            sort = parseInt(perm.DETAIL_SORT) + 1
        })
        this.detailData.detailSort = sort
      }else{
        this.detailData.detailSort = 1
      }
      this.detailData.subjectDetail = ""
      this.detailData.subjectDetailId = ""
    },
    toSaveDetail(){
      this.$refs["subjectDetailForm"].validate((valid) => {
        if (valid) {
          this.$axios.post("/api/subject/savesubjectdetail", this.detailData)
              .then((response) => {
                if (response.data.success) {
                  this.$message({message: "科目明细保存成功", type: "success"})
                  this.unedit = true
                  this.detailData.detailSort = ""
                  this.detailData.subjectDetail = ""
                  this.detailData.subjectDetailId = ""
                  this.loadSubject('refresh')
                } else {
                  this.$alert(response.data.result, "科目明细保存出错")
                }
              })
              .catch((error) => {
                this.$alert(error, "科目明细保存出错")
              })
        }
        else
          return false
      })
    },
    toEditDetail(data){
      this.unedit = false
      this.detailData.subjectDetailId = data.SUBJECT_DETAIL_ID
      this.detailData.subjectSort = data.SUBJECT_SORT
      this.detailData.subjectName = data.SUBJECT_NAME
      this.detailData.detailSort = data.DETAIL_SORT
      this.detailData.subjectDetail = data.SUBJECT_DETAIL
    },
    toDelDetail (data) {
      this.$confirm("确认删除此科目明细?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$axios.post("/api/subject/delsubjectdetail", {subjectDetailId: data.SUBJECT_DETAIL_ID})
            .then((response) => {
              if (response.data.success) {
                this.$message({message: "科目明细删除成功", type: "success"})
                this.loadSubject('refresh')
              }
              else {
                this.$alert(response.data.result, "科目明细删除出错")
              }
            })
            .catch((error) => {
              this.$alert(error, "科目明细删除出错")
            })
      })
    },
    doExport () {
      this.exportTip = "会记科目导出Excel中..."
      this.$axios.post("/api/subject/exportsubjectdata", this.search, {responseType: "blob"})
          .then((response) => {
            this.exportTip = ""
            const err = response.headers["error-content"]
            if (typeof err === "undefined") {
              const blobUrl = window.URL.createObjectURL(new Blob([response.data]))
              let fname = response.headers["content-disposition"].split("filename=")[1]
              fname = decodeURIComponent(fname)
              const a = this.$refs.expLink
              a.href = blobUrl
              a.setAttribute("download", fname)
              a.click()
            }
            else
              this.$alert(decodeURIComponent(err), "会记科目导出出错")
          })
          .catch((error) => {
            this.exportTip = ""
            this.$alert(error, "会记科目导出出错")
          })
    },
  }

}
</script>

<style scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-content {
  color: white;
  /* 样式自定义 */
}
</style>

<!--
用户管理
最后编辑人：张鹏
最后编辑时间：2023-1-6
最后编辑细节：增加重置按钮
-->
<template>
  <el-container class="base-container">
    <el-header class="base-height">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>组织权限</el-breadcrumb-item>
        <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-container class="main-container" ref="mainSector">
      <el-aside width="20%" style="padding: 20px 5px 0 20px;">
        <div style="display: flex;">
          <span>组织机构</span>
          <span style="margin-left: auto; margin-right: 10px">
            <el-tooltip effect="dark" content="展开/收起" placement="top">
              <el-link :underline="false" @click="toggleRowExpansion()">
                <el-icon :size="20"><Sort /></el-icon>
              </el-link>
            </el-tooltip>
          </span>
        </div>
        <el-divider style="margin: 15px 0;"/>
        <div style="height: calc(100% - 65px); overflow: auto;">
          <el-tree
              :data="treeData"
              :props="treeProps"
              node-key="value"
              :default-expand-all="expanded"
              :expand-on-click-node="false"
              ref="organTree">
            <template #default="{ node, data }">
                <span @click="toShowUser(data)">
                  <i class="iconfont icon-zuzhiguanli" style="font-size: 18px;" v-if="data.type === 'company'"></i>
                  <i class="iconfont icon-tree-department-1" style="font-size: 18px;" v-else></i>
                  {{ node.label }}
                </span>
            </template >
          </el-tree>
        </div>
      </el-aside>
      <el-main style="height: 100%; border-left: 10px solid #f5f5f5; overflow:hidden;">
        <el-row>
          <el-col :span="3">
            <el-button type="success" @click="toAddUser()">
              <el-icon><Plus /></el-icon><span class="icon-right">新建用户</span>
            </el-button>
          </el-col>
          <el-col :span="5" class="input-search">
            <el-input v-model="search.user_name" placeholder="请输入用户姓名" @keyup.enter="loadUser" @clear="loadUser" clearable size="default"></el-input>
          </el-col>
          <el-col :span="5" class="input-search">
            <el-input v-model="search.user_account" placeholder="请输入用户账号" @keyup.enter="loadUser" @clear="loadUser" learable size="default"></el-input>
          </el-col>
          <el-col :span="5" class="input-search">
            <el-input v-model="search.cellphone" placeholder="请输入电话号码" @keyup.enter="loadUser" @clear="loadUser" learable size="default"></el-input>
          </el-col>
          <el-col :span="5">
            <el-button
                type="primary"
                size="default"
                @click="loadUser">查询</el-button>
            <el-button
                size="default"
                @click="toReset">重置</el-button>
          </el-col>
          <el-col :span="1" style="text-align: right;">
            <el-tooltip effect="dark" content="刷新" placement="top">
              <el-link :underline="false" @click="toRefresh()">
                <el-icon :size="20"><Refresh /></el-icon>
              </el-link>
            </el-tooltip>
          </el-col>
        </el-row>
        <el-divider style="margin: 6px 0;"/>
        <div style="padding:5px;">
          <el-table
              :height="tableHeight"
              :data="userListData"
              style="width: 100%"
              v-loading="loading">
            <template v-slot:empty>
              <el-empty description="暂无数据" :image-size="120"></el-empty>
            </template>
            <el-table-column
                prop="USER_NAME"
                label="姓名"
                show-overflow-tooltip
                align="center"
                width="80px"
            >
            </el-table-column>
            <el-table-column
                prop="USER_ACCOUNT"
                label="账号"
                show-overflow-tooltip
                align="center"
                width="80px"
            >
            </el-table-column>
            <el-table-column
                prop="ROLE_NAME"
                label="角色"
                show-overflow-tooltip
                align="center"
                min-width="100px"
            >
            </el-table-column>
            <el-table-column
                prop="CELLPHONE"
                label="电话"
                width="120px"
                show-overflow-tooltip
                align="center"
            >
            </el-table-column>
            <el-table-column
                prop="ORGAN_DEP"
                label="所属机构"
                show-overflow-tooltip
                align="center"
                min-width="250px"
            >
            </el-table-column>
            <el-table-column
                label="状态"
                min-width="80px"
                align="center">
              <template #default="scope">
                <el-switch
                    v-model="scope.row.STATE"
                    active-value="1"
                    inactive-value="0"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    inline-prompt
                    active-text="启"
                    inactive-text="禁"
                    @change="switchState(scope.row)">
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column
                label="微信绑定"
                align="center"
                min-width="80px">
              <template #default="scope">
                <el-tag size="small" :type="scope.row.WEIXIN_ID !== null ? 'success' : 'warning'" effect="plain">
                  {{ scope.row.WEIXIN_ID !== null ? '已绑定' : '未绑定' }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                min-width="300px"
                align="center">
              <template #default="scope">
                <el-button class="table-button"
                           size="small"
                           type="primary"
                           @click="toEdit(scope.row)">编辑</el-button>
                <el-button class="table-button"
                           size="small"
                           type="danger"
                           @click="toDel(scope.row)">删除</el-button>
                <el-button class="table-button"
                           type="warning"
                           size="small"
                           @click="doChange(scope.row)">重置密码</el-button>
                <el-button class="table-button"
                           type="success"
                           size="small"
                           v-if="scope.row.WEIXIN_ID !== null"
                           @click="doUnbinding(scope.row)">微信解绑</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
              background
              v-model:current-page="search.page"
              v-model:page-size="search.pagesize"
              layout="total, prev, pager, next, jumper"
              @current-change="loadUser"
              :total="total"
              style="margin-top: 10px;"
          >
          </el-pagination>
        </div>

        <el-dialog
            width="1000px"
            :title="UserTitle"
            v-model="dailVisi"
            :close-on-click-modal="false"
            :close-on-press-escape="false">
          <div style="height: calc(100% - 100px); overflow: auto;">
            <el-form
                :inline="true"
                :model="UserData"
                :rules="rules"
                ref="userForm"
                label-width="130px"
            >
              <el-form-item label="所属组织" prop="organid" class="form-col">
                <el-cascader
                    style="width: 100%;"
                    v-model="UserData.organ_dep"
                    :props="props"
                    clearable
                    @change="toRoleDep($event)"
                    :options="treeData" >
                </el-cascader>
              </el-form-item>
              <el-form-item label="用户角色" prop="userrole" class="form-col">
                <el-select v-model="UserData.userrole" placeholder="请选择用户角色" :disabled="unedit" style="width: 100%;">
                  <el-option
                      v-for="item in userrolelist"
                      :key="item.USER_ROLE"
                      :label="item.ROLE_NAME"
                      :value="item.USER_ROLE">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="用户姓名:" prop="username" class="form-col">
                  <el-input v-model="UserData.username" />
              </el-form-item>
              <el-form-item label="手机号码:" prop="cellphone" class="form-col">
                <el-input v-model="UserData.cellphone" />
              </el-form-item>
              <el-form-item label="用户账号:" prop="useraccount" class="form-col">
                <el-input v-model="UserData.useraccount" />
              </el-form-item>
              <el-form-item label="密码:" prop="userpassword" type="password" class="form-col" v-if="pass === true" >
                <el-input v-model="UserData.userpassword" show-password />
              </el-form-item>
            </el-form>
            <div class="div-button">
              <el-button type="primary" @click="toSave">保 存</el-button>
            </div>
          </div>
        </el-dialog>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  name: "UserMge",
  data () {
    return {
      expanded:true,
      treeData: [],
      treeProps:{
        label:'text', value:'value', children:'children',
      },
      search: {
        organid:"",
        depid:"",
        user_name:"",
        user_account:"",
        cellphone:"",
        page: 1,
        pagesize: 10,
      },
      loading:false,
      tableHeight: 0,
      userListData:[],
      total: 0,
      UserTitle: "",
      dailVisi: false,
      props:{
        label:'text',value:'value',children:'children',
        emitPath:false,
      },
      unedit: true,
      UserData: {
        userid:"",
        organ_dep:"",
        organid: "",
        depid:"",
        userrole: "",
        username: "",
        cellphone: "",
        useraccount: "",
        userpassword: "",
        state:"1",
      },
      alluserrolelist:[],
      userrolelist:[],
      organlist:[],
      pass: true,
      rules: {
        organid: [{required: true, message: "请选择所属机构", trigger: "blur"}],
        userrole: [{required: true, message: "请选择用户角色", trigger: "blur"}],
        username: [{required: true, message: "请填写用户名称", trigger: "blur"}, {max: 50, message: "不能超过50个字", trigger: "blur"}],
        cellphone: [{required: true, message: "请填写手机号码", trigger: "blur"}, {pattern: /^1\d{10}$/, message: '不是正确的手机号', trigger: "blur"}],
        useraccount: [{required: true, message: "请填写用户账号", trigger: "blur"}, {max: 50, message: "不能超过50个字", trigger: "blur"}],
        userpassword: [{required: true, message: "请填写用户密码", trigger: "blur"},{pattern: /^(?=.*[0-9].*)(?=.*[A-Z].*)(?=.*[a-z].*).{6,}$/, message: "密码至少6位，且包含大小写字母和数字", trigger: "blur"}],
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.calcTableHeight()
      window.removeEventListener("resize", this.calcTableHeight)
      window.addEventListener("resize", this.calcTableHeight)
      this.loadData()
      this.loadRoleData()
      this.loadUser()
    })
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.calcTableHeight)
  },
  methods: {
    calcTableHeight() {
      let mainHeight = window.getComputedStyle(this.$refs.mainSector.$el).height
      if (mainHeight.indexOf("px") !== -1)
        mainHeight = Number(mainHeight.substring(0, mainHeight.indexOf("px")))
      else
        mainHeight = Number(mainHeight)
      this.tableHeight = mainHeight - 120
    },
    toRefresh(){
      this.search.organid = ""
      this.search.depid = ""
      this.loadUser("refresh")
    },
    /** 展开收缩 */
    toggleRowExpansion(){
      const nodes = this.$refs.organTree.store._getAllNodes();
      nodes.forEach(item=>{
        item.expanded = !item.expanded
      })
    },
    loadData() {
      this.$axios.get("/api/common/getorgantree")
          .then((response) => {
            const data = response.data
            if (data.success) {
              this.treeData = data.result
              this.treeData.forEach(item => {
                if(item.type ==="company" && item.children.length === 0){
                  item["disabled"] = true
                }else{
                  item.children.forEach(i => {
                    if(i.type ==="company" && i.children.length === 0){
                      i["disabled"] = true
                    }
                  })
                }
              })
            } else {
              this.$alert(response.data.result, "获取组织列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取组织列表出错")
          })
    },

    toShowUser (node) {
      if(node.type === "department"){
        this.search.organid = node.ORGAN_ID
        this.search.depid = node.DEPARTMENT_ID
      }else{
        this.search.organid = node.ORGAN_ID
        this.search.depid = ""
      }
      this.loadUser()
    },
    loadUser(d) {
      this.loading = true
      this.$axios.post("/api/user/loaduserinfo", this.search)
          .then((response) => {
            if (response.data.success) {
              this.loading = false
              this.total = response.data.result.totalrecords
              this.userListData = response.data.result.datalist
              if(d === "refresh"){
                this.$message({message: "刷新成功", type: "success"})
              }
            }
            else {
              this.$alert(response.data.result, "获取用户列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取用户列表出错")
          })
    },
    toReset() {
      this.search.unit_type = ""
      this.search.unit_id = ""
      this.search.user_name = ""
      this.search.user_account = ""
      this.search.cellphone = ""
      this.loadUser()
    },
    loadRoleData() {
      this.$axios.get("/api/user/getrolelist")
          .then((response) => {
            const data = response.data
            if (data.success) {
              this.alluserrolelist = data.result
            } else {
              this.$alert(response.data.result, "获取角色列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取角色列表出错")
          })
    },
    toRoleDep(data){
      if(data != null) {
        const parts = data.split('-');
        this.UserData.organid = parts[0]
        this.UserData.depid = parts[1]
        const role = []
        this.alluserrolelist.forEach(item => {
          if (parseInt(item.ORGAN_ID) === parseInt(this.UserData.organid) && parseInt(item.DEPARTMENT_ID) === parseInt(this.UserData.depid))
            role.push(item)
        })
        this.userrolelist = role
        this.unedit = false
      }else{
        this.unedit = true
        this.UserData.userrole = ""
      }
    },
    toAddUser () {
      this.UserTitle = "用户新增"
      this.dailVisi = true
      this.pass = true
      this.unedit = true
      this.UserData.userid = ""
      this.UserData.username = ""
      this.UserData.cellphone = ""
      this.UserData.useraccount = ""
      this.UserData.userpassword = ""
      this.UserData.userrole = ""
      this.UserData.organ_dep = ""
      this.UserData.organid = ""
      this.UserData.depid = ""
      if (this.$refs.userForm)
        this.$refs.userForm.clearValidate()
    },
    toEdit (data){
      const role = []
      this.alluserrolelist.forEach(item => {
        if (parseInt(item.ORGAN_ID) === parseInt(data.ORGAN_ID) && parseInt(item.DEPARTMENT_ID) === parseInt(data.DEPARTMENT_ID))
          role.push(item)
      })
      this.userrolelist = role
      this.unedit = false
      this.UserData.userid = data.USER_ID
      this.UserData.organ_dep = data.ORGAN_ID+ "-" +data.DEPARTMENT_ID
      this.UserData.organid = data.ORGAN_ID
      this.UserData.depid = data.DEPARTMENT_ID
      this.UserData.username = data.USER_NAME
      this.UserData.cellphone = data.CELLPHONE
      this.UserData.useraccount = data.USER_ACCOUNT
      this.UserData.userrole = parseInt(data.USER_ROLE)
      this.UserTitle = "用户编辑"
      this.dailVisi = true
      this.pass = false
      if (this.$refs.userForm)
        this.$refs.userForm.clearValidate()
    },

    toSave() {
      this.$refs["userForm"].validate((valid) => {
        if (valid) {
          this.$axios.post("/api/user/saveuserdata", this.UserData)
              .then((response) => {
                if (response.data.success) {
                  this.$message({message: "用户保存成功", type: "success"})
                  this.dailVisi = false
                  this.loadUser()
                } else {
                  this.$alert(response.data.result, "用户保存出错")
                }
              })
              .catch((error) => {
                this.$alert(error, "用户保存出错")
              })
        }
        else
          return false
      })
    },
    //删除
    toDel (data) {
      this.$confirm("确认删除此用户?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$axios.post("/api/user/deluser", {userid: data.USER_ID})
            .then((response) => {
              if (response.data.success) {
                this.$message({message: "用户删除成功", type: "success"})
                this.loadUser()
              }
              else {
                this.$alert(response.data.result, "用户删除出错")
              }
            })
            .catch((error) => {
              this.$alert(error, "用户删除出错")
            })
      })
    },

    //切换状态
    switchState (data) {
      this.$axios.post("/api/user/switchwish", {userid: data.USER_ID, state: data.STATE})
          .then((response) => {
            if (!response.data.success) {
              this.$alert(response.data.result, "用户状态切换出错")
              this.loadUser()
            }
          })
          .catch((error) => {
            this.$alert(error, "用户状态切换出错")
            this.loadUser()
          })
    },
    //重置密码
    doChange (data) {
      this.$confirm("确认重置此用户的密码吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$axios.post("/api/user/changepassword", {userid: data.USER_ID})
            .then((response) => {
              if (response.data.success) {
                this.loadUser()
                this.$message({message: "密码重置成功，现密码为123456", type: "success"})
              } else {
                this.$alert(response.data.result, "密码重置出错")
              }
            })
            .catch((error) => {
              this.$alert(error, "密码重置出错")
            })
      })
    },

    //微信解绑
    doUnbinding (data) {
      this.$confirm("确认解绑此用户的微信吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$axios.post("/api/user/unbindWeChat", {userid: data.USER_ID})
            .then((response) => {
              if (response.data.success) {
                this.loadUser()
                this.$message({message: "微信解绑成功", type: "success"})
              } else {
                this.$alert(response.data.result, "微信解绑出错")
              }
            })
            .catch((error) => {
              this.$alert(error, "微信解绑出错")
            })
      })
    },
  }
}
</script>

<style scoped>

</style>

<template>
  <el-container class="base-container">
    <el-header class="base-height">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>数据管理</el-breadcrumb-item>
        <el-breadcrumb-item>出纳凭证明细</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-container class="main-container" ref="mainSector">
      <el-main>
        <el-row>
          <el-col :span="3" class="input-search">
            <el-date-picker
                v-model="searchDetail.recordMonth"
                value-format="YYYY-MM"
                type="month"
                style="width: 100%;"
                @change="loadVoucherDetail()">
            </el-date-picker>
          </el-col>
          <el-col :span="4" class="input-search">
            <el-cascader
                placeholder="请选择公司或部门"
                style="width: 100%;"
                v-model="searchDetail.organOrDep"
                :props="organprops"
                clearable
                :show-all-levels="false"
                @change="loadVoucherDetail($event)"
                :options="OrganTreeData" >
            </el-cascader>
          </el-col>
          <el-col :span="3" class="input-search">
            <el-select v-model="searchDetail.subjectType" placeholder="请选择科目类别" @change="loadVoucherDetail" clearable>
              <el-option
                  v-for="item in subjectTypeData"
                  :key="item.CODE"
                  :label="item.CODE_NAME"
                  :value="item.CODE">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4" class="input-search">
            <el-cascader
                placeholder="请选择会计科目"
                style="width: 100%;"
                v-model="searchDetail.subjectSort"
                :props="props"
                clearable
                :show-all-levels="false"
                @change="changeDetail"
                :options="treeData" >
            </el-cascader>
          </el-col>
          <el-col :span="3" class="input-search">
            <el-select v-model="searchDetail.subjectDetail" placeholder="请选择科目明细" clearable @change="loadVoucherDetail()" :disabled="disabled">
              <el-option
                  v-for="item in subjectDetailList"
                  :key="item.SUBJECT_DETAIL"
                  :label="item.SUBJECT_DETAIL"
                  :value="item.SUBJECT_DETAIL">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="3" class="input-search">
            <el-input v-model="searchDetail.num" placeholder="请输入凭证号" @keyup.enter="loadVoucherDetail" @clear="loadVoucherDetail" clearable size="default"></el-input>
          </el-col>
          <el-col :span="3">
            <el-button
                type="primary"
                size="default"
                @click="loadVoucherDetail">查询</el-button>
            <el-button
                size="default"
                @click="toReset">重置</el-button>
          </el-col>
          <el-col :span="1" style="text-align: right;">
            <el-tooltip effect="dark" content="刷新" placement="top">
              <el-link :underline="false" @click="toRefresh()">
                <el-icon :size="20"><Refresh /></el-icon>
              </el-link>
            </el-tooltip>
          </el-col>
        </el-row>

        <el-table
            :height="tableHeight"
            :data="voucherDetailList">
          <template v-slot:empty>
            <el-empty description="暂无数据" :image-size="120"></el-empty>
          </template>
          <el-table-column
              prop="RECORD_TIME"
              label="日期"
              show-overflow-tooltip
              align="center"
              min-width="80px"
          >
          </el-table-column>
          <el-table-column
              prop="VOUCHER_NUM"
              label="凭证号"
              align="center"
              show-overflow-tooltip
              width="70px"
          >
          </el-table-column>
          <el-table-column
              prop="ABSTRACT"
              label="摘要"
              align="center"
              show-overflow-tooltip
              min-width="100px"
          >
          </el-table-column>
          <el-table-column
              prop="SUBJECT_TYPE_NAME"
              label="科目类别"
              show-overflow-tooltip
              align="center"
              min-width="80px"
          >
          </el-table-column>
          <el-table-column
              prop="SUBJECT_NAME"
              label="科目名称"
              show-overflow-tooltip
              align="center"
              min-width="80px"
          >
          </el-table-column>
          <el-table-column
              prop="SUBJECT_DETAIL"
              label="科目明细"
              show-overflow-tooltip
              align="center"
              min-width="100px"
          >
          </el-table-column>
          <el-table-column
              prop="INITIAL_DATA_CARRYOVER"
              label="期初结转"
              show-overflow-tooltip
              align="center"
              min-width="70px"
          >
          </el-table-column>
          <el-table-column
              label="收入金额"
              show-overflow-tooltip
              align="center"
              min-width="60px"
          >
            <template #default="scope">
                  <span v-if="scope.row.REVENUE_MONEY !== null && scope.row.REVENUE_MONEY !== ''" class="disable">
                    {{ scope.row.REVENUE_MONEY }}元
                  </span>
            </template>
          </el-table-column>
          <el-table-column
              label="支出金额"
              show-overflow-tooltip
              align="center"
              min-width="60px"
          >
            <template #default="scope">
                  <span v-if="scope.row.EXPENDITURE_MONEY !== null && scope.row.EXPENDITURE_MONEY !== ''" class="disable">
                    {{ scope.row.EXPENDITURE_MONEY }}元
                  </span>
            </template>
          </el-table-column>
          <el-table-column
              prop="DOCUMENT"
              label="单据"
              show-overflow-tooltip
              align="center"
              min-width="60px"
          >
          </el-table-column>
        </el-table>
        <el-pagination
            background
            v-model:current-page="searchDetail.page"
            v-model:page-size="searchDetail.pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            @current-change="loadVoucherDetail"
            @size-change="loadVoucherDetail"
            :page-sizes="[20, 100, 500, 1000]"
            :total="total1"
            style="margin-top: 10px;"
        >
        </el-pagination>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  name: "VoucherDetail",
  data() {
    return {
      tableHeight: 0,
      searchDetail:{
        subjectSort:"",
        recordMonth:"",
        subjectType: "",
        subjectDetail: "",
        num:"",
        organOrDep:"",
        s_organid:"",
        s_depid:"",
        page: 1,
        pagesize: 20,
      },
      OrganTreeData:[],
      organprops:{
        label:'text',value:'value',children:'children',
        checkStrictly: true,
        emitPath:false,
      },
      total1: 0,
      voucherDetailList:[],
      treeData:[],
      subjectTypeData: [],
      subjectDetailList:"",
      disabled:true,
      props:{
        label:'label',value:'id',children:'children',
        emitPath:false,
      },
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.calcTableHeight()
      window.removeEventListener("resize", this.calcTableHeight)
      window.addEventListener("resize", this.calcTableHeight)
      this.searchDetail.recordMonth = this.current("month")
      this.searchDetail.organOrDep = "company-"+sessionStorage.getItem("organ-organid")
      this.loadCode()
      this.loadSubjectData()
      this.loadVoucherDetail()
    })
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.calcTableHeight)
  },
  methods: {
    calcTableHeight() {
      let mainHeight = window.getComputedStyle(this.$refs.mainSector.$el).height
      if (mainHeight.indexOf("px") !== -1)
        mainHeight = Number(mainHeight.substring(0, mainHeight.indexOf("px")))
      else
        mainHeight = Number(mainHeight)
      this.tableHeight = mainHeight - 120
    },
    current(d) {
      var nowDate = new Date();
      var date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        day: nowDate.getDate()
      };
      if(d === "month")
        return  date.year + "-" + (date.month >= 10 ? date.month : "0" + date.month);
      else if(d === "day")
        return  date.year + "-" + (date.month >= 10 ? date.month : "0" + date.month) + "-" + (date.day >= 10 ? date.day : "0" + date.day);
    },
    changeDetail(){
      let f=[]
      if(this.searchDetail.subjectSort !== "" && this.searchDetail.subjectSort !== null ) {
        this.disabled = false
         f = this.allSubjectDetail.filter((item) => {
          return Number(item.SUBJECT_SORT) === Number(this.searchDetail.subjectSort)
        })
      }else{
        this.disabled = true
      }
      this.subjectDetailList = f
      this.searchDetail.subjectDetail = ""
      this.loadVoucherDetail()
    },
    loadCode() {
      this.$axios.post("/api/common/getcodelists", {codes: [ "SUBJECT_TYPE"]})
          .then((response) => {
            const data = response.data
            if (data.success) {
              this.subjectTypeData = data.result.SUBJECT_TYPE
            } else {
              this.$alert(response.data.result, "获取代码列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取代码列表出错")
          })
      this.$axios.get("/api/common/getqueryorgantree")
          .then((response) => {
            const data = response.data
            if (data.success) {
              this.OrganTreeData = data.result
            } else {
              this.$alert(response.data.result, "获取组织列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取组织列表出错")
          })
    },
    loadSubjectData() {
      this.$axios.get("/api/common/getsubjecttree")
          .then((response) => {
            if (response.data.success) {
              this.treeData = response.data.result
            }
            else {
              this.$alert(response.data.result, "获取会计科目列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取会计科目列表出错")
          })

      this.$axios.post("/api/common/getallsubjectdetail", this.searchDetail)
          .then((response) => {
            if (response.data.success) {
              this.allSubjectDetail = response.data.result
            }
            else {
              this.$alert(response.data.result, "获取会计科目列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取会计科目列表出错")
          })
    },
    loadVoucherDetail(d) {
      if(this.searchDetail.organOrDep !== "" && this.searchDetail.organOrDep !== null){
        const parts = this.searchDetail.organOrDep.toString().split('-');
        if(parts[0] === "company"){
          this.searchDetail.s_organid = parts[1]
          this.searchDetail.s_depid = ""
        }else{
          this.searchDetail.s_organid = parts[0]
          this.searchDetail.s_depid = parts[1]
        }
      }else{
        this.searchDetail.s_organid = ""
        this.searchDetail.s_depid = ""
      }
      this.$axios.post("/api/voucher/loadvoucherdetail", this.searchDetail)
          .then((response) => {
            if (response.data.success) {
              this.total1 = response.data.result.totalrecords
              this.voucherDetailList = response.data.result.datalist
              if (d === "refresh") {
                this.$message({message: "刷新成功", type: "success"})
              }
            } else {
              this.$alert(response.data.result, "获取出纳凭证明细列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取出纳凭证明细列表出错")
          })
    },
    toRefresh() {
      this.searchDetail.subjectSort = ""
      this.searchDetail.subjectType = ""
      this.searchDetail.subjectDetail = ""
      this.searchDetail.num = ""
      this.searchDetail.organOrDep = "company-"+sessionStorage.getItem("organ-organid")
      this.searchDetail.s_organid = ""
      this.searchDetail.s_depid = ""
      this.loadVoucherDetail("refresh")
    },
    toReset() {
      this.searchDetail.subjectSort = ""
      this.searchDetail.subjectType = ""
      this.searchDetail.subjectDetail = ""
      this.searchDetail.num = ""
      this.searchDetail.organOrDep = "company-"+sessionStorage.getItem("organ-organid")
      this.searchDetail.s_organid = ""
      this.searchDetail.s_depid = ""
      this.loadVoucherDetail()
    },
  }
}
</script>

<style scoped>

</style>
